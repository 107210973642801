.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 2vh;
  max-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.station-view {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.weather-station-selector-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  background: #f5f5f5;
}

.weather-station-selector {
  order: 0;
  flex-grow: 2;
  min-width: 50px;
}

.weather-station-date-selector-start {
  order: 1;
  flex-grow: 1;
}

.weather-station-date-selector-end {
  order: 2;
  flex-grow: 1;
}

.weather-data-plot {
  margin-top: 10px;
}

.time-picker-box {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.axis-vertical-label-text {
  font-size: 10px;
}

.axis-vertical-tick-text {
  font-size: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
